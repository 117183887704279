@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @font-face {
    font-family: Nunito;
    font-weight: 400;
    src: url(./fonts/Nunito-Italic.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: Nunito;
    font-weight: 400;
    src: url(./fonts/Nunito-Regular.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: Nunito;
    font-weight: 700;
    src: url(./fonts/Nunito-BoldItalic.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: Nunito;
    font-weight: 700;
    src: url(./fonts/Nunito-Bold.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: Nunito;
    font-weight: 900;
    src: url(./fonts/Nunito-Black.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: AbrilFatface;
    font-weight: 400;
    src: url(./fonts/AbrilFatface-Regular.ttf) format("truetype");
    font-display: swap;
  }

  html,
  body {
    @apply font-NunFont;
    @apply text-base;

    @apply text-black;
  }
  @screen md {
    html,
    body {
      @apply text-xl;
    }
  }
  h1 {
    @apply font-FatFont;
    @apply text-5xl;
    @apply tracking-wide;
    @apply mb-4;
    @apply font-normal;
  }
  h2 {
    @apply font-FatFont;
    @apply text-2xl;
    @apply font-normal;
  }
  h3 {
    @apply font-NunFont;
    @apply text-lg;
    @apply uppercase;
    @apply font-black;
  }
  p,
  ul {
    @apply text-base;
    @apply mb-1;
  }
  p a {
    @apply font-bold;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-shadow {
  text-shadow: #45403b 1px 1px 3px;
}
.xdrop-shadow {
  filter: drop-shadow(1px 1px 3px #45403b);
}

/* ////////////////////////////////////// */
/* hamburger stuff */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 48px;
  height: 30px;
  right: 20px;
  top: 40px;
  z-index: 1 !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #0E0D0C;
  width: 90%;
  display: block;
  margin: 0 auto;
  border-radius: 3px;
  box-shadow: #2e3440 1px 1px 3px;
}
.bm-burger-bars:nth-child(1),
.bm-burger-bars:nth-child(3) {
  width:70%;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #45403b;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 48px !important;
  width: 48px !important;
  right: 35px !important;
  top: 35px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #0E0D0C;
  width: 6px !important;
  height: 35px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  /* i touched it here */
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: #ccfff5;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #0E0D0C;
  padding: 0.8em;
}
.bm-item-list a {
  font-weight: 700;
    margin-bottom: 5px;
    display: block;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  /* i touched it here */
  top: 0;
}
/* end hamburger stuff */
/* ////////////////////////////////////// */

@media only screen and (max-width: 600px) {
  .desktopOnly {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .mobileOnly {
    display: none;
  }
}

/* ////////////////////////////////////// */
/* transition group stuff */
.container {
  position: relative;
}

.page {
  position: absolute;
  width: 100%;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
/* end - transition group stuff */
/* ////////////////////////////////////// */

/* lightbox helper */
.styles_lightroom__1X2qE {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  /* transform: translateX(-50%); */
}
/*.styles_topmenu__oGT_u img:first-child {
  display: none;
} */

/* ////////////////////////////////////// */
/* styles for active nav links */
.activeBar {
  height: 2px;
  background: #0E0D0C;
  width: 0%;
  transition: all .5s;
  border-radius: 0px;
}
.selected .activeBar,
.mnlink:hover .activeBar {
  width: 100%;
}
/* end - styles for active nav links */
/* ////////////////////////////////////// */


.cool-hover figure {
  transform: scale(1);
  transition: .5s ease-in-out;
}
.cool-hover:hover figure {
  transform: scale(1.05);
}
.cool-hover section {
  top: 0;
  left: 0;
}

.mobinav  svg {
  display: none;
}
.mobinav .active-page svg {
  display: inline;
}

/* remove background attachment on touch devices because safari as of 11.22 doesn't know how to impliment correctly */
@media (hover: none) and (pointer: coarse) {
  .bug-fix {
    background-attachment: scroll !important;
  }
}

/* body {
  background: rgb(64,201,199);
background: linear-gradient(138deg, rgba(64,201,199,1) 30%, rgba(88,252,220,1) 50%, rgba(64,201,199,1) 70%);
} */